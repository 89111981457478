import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Modal from "react-modal";

const ImageWithTabContent = ({ module }) => {
  const [tabOption, setTabOption] = useState(module.tabs[0].tabHeading);
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  function openModal(data) {
    document.body.classList.add("modal-open");
    setIsOpen(true);
  }
  function afterOpenModal3() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    document.body.classList.remove("modal-open");
    setIsOpen(false);
  }
  function openModal2(data) {
    document.body.classList.add("modal-open");
    setIsOpen2(true);
  }

  function closeModal2() {
    document.body.classList.remove("modal-open");
    setIsOpen2(false);
  }
  function openModal3(data) {
    document.body.classList.add("modal-open");
    setIsOpen3(true);
  }

  function closeModal3() {
    document.body.classList.remove("modal-open");
    setIsOpen3(false);
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // width: "930px",
      padding: "0",
      border: "0",
      borderRadius: "0",
    },
    overlay: {
      zIndex: "99",
    },
  };
  const customStyles3 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
      border: "0",
      borderRadius: "0",
      padding: "50px 35px",
      maxWidth: "1100px",
    },
    overlay: {
      zIndex: "99",
    },
  };
  return (
    <>
      <section className="zigzag lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap items-start">
            <div className="lg:w-6/12 w-full lg:order-2">
              <div className="image-wrapper">
                <GatsbyImage
                  image={getImage(module.image)}
                  alt={module.image.altText}
                ></GatsbyImage>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="zigzag-content lg:max-w-490 lg:mx-auto pt-30 lg:py-30 lg:px-19 fade-ani">
                <span className="text-greenColor text-12 capitalize leading-12 font-400">
                  {module.preHeading}
                </span>
                <div className="title-black mb-30 mt-30">
                  <h3>{module.heading}</h3>
                </div>
                <ul className="tabs flex gap-30 mb-30">
                  {module.tabs.map((item, i) => {
                    return (
                      <li
                        onClick={() => {
                          setTabOption(item.tabHeading);
                        }}
                        className={`tab-link ${
                          tabOption === item.tabHeading ? "tab-current" : ""
                        }`}
                        data-tab={item.tabHeading}
                      >
                        {item.tabHeading}
                      </li>
                    );
                  })}
                </ul>
                <div className="content global-list">
                  {module.tabs.map((item, i) => {
                    return (
                      <div
                        id={item.tabHeading}
                        className={` ${
                          tabOption === item.tabHeading
                            ? "tab-current"
                            : "hidden"
                        }`}
                      >
                        {parse(item.tabDescription)}
                        <span className="special-text">{item.tagline}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="btn-custom mt-30">
                  {module.button &&
                      <Link to={module.button.url} className="btn greenOutlinebtn mdbtn">{module.button.title}</Link>
                    }
                    {module.bookingButton &&
                      <Link to={module.bookingButton.url} className="btn green-btn">{module.bookingButton.title}</Link>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageWithTabContent;
export const ImageWithTabContentFragmentOffer = graphql`
  fragment ImageWithTabContentFragmentOffer on WpOffer_Acfoffercontent_OfferContent_ImageWithTabContent {
    # content
    id
    tabs {
      tabHeading
      tabDescription
      tagline
      fieldGroupName
    }
    imageSize
    imagePosition
    imageBorderStyle
    image {
      gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH, placeholder: BLURRED)
      altText
      uri
      title
    }
    heading
    extraClass
    fieldGroupName
    buttonStyle
    button {
      url
      title
      target
    }
  }
`;
