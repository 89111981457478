import React, { useEffect } from "react";
import Layout from "../components/layout";
import OfferModules from "../components/ModuleOffer/OfferModules";
import { graphql } from "gatsby";
import Seo from "../components/Seo/Seo";

const PageTemplate = (props) => {

  useEffect(() => {
    document.body.classList.remove("mega-menu-open");
    document.body.classList.remove("booking-open");
    document.body.classList.remove("form-enquiry-open");
    document.body.classList.remove("menu-open");
  });
  
  const post = props.data.post;
  const postData = {
    image: props.data.post.featuredImage.node,
    title: props.data.post.title,
    id: props.data.post.id,
  };
  return (
    <Layout>
      <OfferModules postData={postData} modules={post.AcfOfferContent} />
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query OfferById($id: String!) {
    post: wpOffer(id: { eq: $id }) {
      id
      title
      excerpt
      featuredImage {
        node {
          altText
          gatsbyImage(width: 1000, formats: WEBP, layout: FULL_WIDTH)
        }
      }
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...OfferModulesFragment
    }
  }
`;
export const Head = ({ data }) => {
  const post = data.post
  return <Seo seo={post.seo} />
}
