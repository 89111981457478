import React from "react";
import { graphql } from "gatsby";
import Banner from "./Banner";
import ImageContent from "./ImageContent";
import SimpleContent from "./SimpleContent"
import ImageWithTabContent from "./ImageWithTabContent";


const OfferModules = (props) => {
  const modules = props.modules.offerContent;
  const components = { ImageContent,SimpleContent,ImageWithTabContent };
  return (
    <>
      <Banner postData={props.postData} />
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpOffer_Acfoffercontent_OfferContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                i,
                postData: props.postData,
              })
            );
          })
        : null}
    </>
  );
};

export default OfferModules;

export const OfferModulesFragment = graphql`
  fragment OfferModulesFragment on WpOffer {
    AcfOfferContent {
      offerContent {
        __typename
        ...OfferImageContentFragment
        ...OfferImageContentFragment
        ...ImageWithTabContentFragmentOffer
        ...SimpleContentOfferFragment
      }
    }
  }
`;