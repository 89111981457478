import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";

const SimpleContent = ({ module }) => {
  return (
    <section className="common-content lg:py-60 py-30">
      <div className="container-fluid">
        <div className="common-content-inner max-w-750">
          <span class="text-tan text-14 mb-10 inline-block tracking-004em font-400 uppercase font-body">
            offers
          </span>
          <div className="title-black mb-20">
            <h2>{module.heading}</h2>
          </div>
          <div className="content global-list lg:pl-20 lg:max-w-948">{parse(module.description)}</div>
        </div>
      </div>
    </section>
  );
};

export default SimpleContent;

export const SimpleContentOfferFragment = graphql`
  fragment SimpleContentOfferFragment on WpOffer_Acfoffercontent_OfferContent_SimpleContent {
    # content
    id
    description
    extraClass
    heading
    fieldGroupName
  }
`;