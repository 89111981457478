import React from "react";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import parse from "html-react-parser";

const Banner = ({ postData }) => {
  return (
    <section className="hero-section inner-page-banner">
      <div className="h-100 w-100 min-h-700 relative">
        <BackgroundImage
          Tag="section"
          className="h-100pr w-100 object-cover"
          {...convertToBgImage(getImage(postData.image))}
          preserveStackingContext
        ></BackgroundImage>
        <div className="absolute top-0 left-0 right-0 bottom-0 flex align-middle items-center justify-center p-15">
          <div className="text-center content-text">
            <div className="fade-ani">
              <h1 className="text-white mb-15  mx-auto">
                {parse(postData.title)}
              </h1>
              {postData.excerpt && parse(postData.excerpt)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
