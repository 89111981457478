import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';

const ImageContent = ({ module, i, postData }) => {
  return (
    <>
      <section
        className={`zigzag lg:py-60 py-30 ${
          module.extraClass === '' ? '' : module.extraClass
        }`}
      >
        <div className="container-fluid">
          <div className="flex flex-wrap items-center">
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === 'left' ? 'lg:order-2' : ''
              }`}
            >
              <div className="zigzag-content lg:max-w-450 lg:mx-auto py-30 lg:px-19 fade-ani">
                <span className="text-greenColor text-12 capitalize leading-12 font-400">
                  {module.preHeading}
                </span>
                <div className="title-black mb-30 mt-30">
                  <h3>{module.heading}</h3>
                </div>
                <div className="content global-list">
                  {parse(module.description)}
                </div>
                <div className="btn-custom mt-30">
                  {module.button && (
                    <Link
                      to={module.button.url}
                      className="btn greenOutlinebtn mdbtn"
                    >
                      {module.button.title}
                    </Link>
                  )}
                  {module.buttonTwo && (
                    <Link
                      to={module.buttonTwo.url}
                      target={module.buttonTwo.target}
                      className="btn green-btn"
                    >
                      {module.buttonTwo.title}
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === 'right' ? '' : ''
              }`}
            >
              <GatsbyImage
                image={getImage(module.image)}
                alt={module.image.altText}
              ></GatsbyImage>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageContent;
export const OfferImageContentFragment = graphql`
  fragment OfferImageContentFragment on WpOffer_Acfoffercontent_OfferContent_ImageContent {
    id
    sectionStyle
    preHeading
    imageSize
    imagePosition
    imageBorderStyle
    image {
      gatsbyImage(
        width: 1000
        formats: WEBP
        layout: FULL_WIDTH
        placeholder: BLURRED
      )
      altText
      uri
      title
    }
    heading
    extraClass
    description
    buttonStyle
    button {
      target
      title
      url
    }
    buttonTwo {
      target
      title
      url
    }
  }
`;
